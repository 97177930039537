exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-guides-index-js": () => import("./../../../src/pages/guides/index.js" /* webpackChunkName: "component---src-pages-guides-index-js" */),
  "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-guides-christmas-mdx": () => import("./../../../src/pages/guides/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/guides/christmas.mdx" /* webpackChunkName: "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-guides-christmas-mdx" */),
  "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-guides-fathersday-mdx": () => import("./../../../src/pages/guides/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/guides/fathersday.mdx" /* webpackChunkName: "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-guides-fathersday-mdx" */),
  "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-airpods-mdx": () => import("./../../../src/pages/guides/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/reviews/airpods.mdx" /* webpackChunkName: "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-airpods-mdx" */),
  "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-logitechproxheadset-mdx": () => import("./../../../src/pages/guides/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/reviews/logitechproxheadset.mdx" /* webpackChunkName: "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-logitechproxheadset-mdx" */),
  "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-macbookair-mdx": () => import("./../../../src/pages/guides/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/reviews/macbookair.mdx" /* webpackChunkName: "component---src-pages-guides-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-macbookair-mdx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-reviews-index-js": () => import("./../../../src/pages/reviews/index.js" /* webpackChunkName: "component---src-pages-reviews-index-js" */),
  "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-guides-christmas-mdx": () => import("./../../../src/pages/reviews/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/guides/christmas.mdx" /* webpackChunkName: "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-guides-christmas-mdx" */),
  "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-guides-fathersday-mdx": () => import("./../../../src/pages/reviews/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/guides/fathersday.mdx" /* webpackChunkName: "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-guides-fathersday-mdx" */),
  "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-airpods-mdx": () => import("./../../../src/pages/reviews/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/reviews/airpods.mdx" /* webpackChunkName: "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-airpods-mdx" */),
  "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-logitechproxheadset-mdx": () => import("./../../../src/pages/reviews/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/reviews/logitechproxheadset.mdx" /* webpackChunkName: "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-logitechproxheadset-mdx" */),
  "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-macbookair-mdx": () => import("./../../../src/pages/reviews/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/reviews/macbookair.mdx" /* webpackChunkName: "component---src-pages-reviews-mdx-frontmatter-slug-js-content-file-path-opt-build-repo-blog-reviews-macbookair-mdx" */)
}

